import axios from '@/common/axios'
import qs from 'qs'

export function addSurgicalElectrodeSterilization (data) {
  return axios({
    method: 'post',
    url: '/production/surgicalElectrodeSterilization/add',
    data: qs.stringify(data)
  })
}

export function deleteSurgicalElectrodeSterilization (id) {
  return axios({
    method: 'delete',
    url: '/production/surgicalElectrodeSterilization/delete/' + id
  })
}

export function updateSurgicalElectrodeSterilization (data) {
  return axios({
    method: 'put',
    url: '/production/surgicalElectrodeSterilization/update',
    data: qs.stringify(data)
  })
}

export function selectSurgicalElectrodeSterilizationInfo (id) {
  return axios({
    method: 'get',
    url: '/production/surgicalElectrodeSterilization/info/' + id
  })
}

export function selectSurgicalElectrodeSterilizationList (query) {
  return axios({
    method: 'get',
    url: '/production/surgicalElectrodeSterilization/list',
    params: query
  })
}

export function reviewSurgicalElectrodeSterilization (data) {
  return axios({
    method: 'put',
    url: '/production/surgicalElectrodeSterilization/updateReview',
    data: qs.stringify(data)
  })
}
