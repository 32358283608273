<template>
  <div id="surgicalElectrodeSterilization">
    <el-dialog
      :title="surgicalElectrodeSterilizationFormTitle"
      width="1200px"
      :visible.sync="surgicalElectrodeSterilizationDialogVisible"
      :close-on-click-modal="false"
      @close="surgicalElectrodeSterilizationDialogClose"
    >
      <el-form
        ref="surgicalElectrodeSterilizationFormRef"
        :model="surgicalElectrodeSterilizationForm"
        :rules="surgicalElectrodeSterilizationFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input
                v-model="surgicalElectrodeSterilizationForm.deviceName"
                placeholder="请输入设备名称"
                clearable
                :disabled="surgicalElectrodeSterilizationFormTitle !== '新增一次性使用手术电极灭菌记录表'
                  && surgicalElectrodeSterilizationFormTitle !== '修改一次性使用手术电极灭菌记录表'
                  && surgicalElectrodeSterilizationFormTitle !== '一次性使用手术电极灭菌记录表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备编号" prop="deviceNo">
              <el-input
                v-model="surgicalElectrodeSterilizationForm.deviceNo"
                placeholder="请输入设备编号"
                clearable
                :disabled="surgicalElectrodeSterilizationFormTitle !== '新增一次性使用手术电极灭菌记录表'
                  && surgicalElectrodeSterilizationFormTitle !== '修改一次性使用手术电极灭菌记录表'
                  && surgicalElectrodeSterilizationFormTitle !== '一次性使用手术电极灭菌记录表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="灭菌批号" prop="sterilizationBatchNo">
              <el-input
                v-model="surgicalElectrodeSterilizationForm.sterilizationBatchNo"
                placeholder="请输入灭菌批号"
                clearable
                :disabled="surgicalElectrodeSterilizationFormTitle !== '新增一次性使用手术电极灭菌记录表'
                  && surgicalElectrodeSterilizationFormTitle !== '修改一次性使用手术电极灭菌记录表'
                  && surgicalElectrodeSterilizationFormTitle !== '一次性使用手术电极灭菌记录表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="灭菌日期" prop="sterilizationDate">
              <el-date-picker
                v-model="surgicalElectrodeSterilizationForm.sterilizationDate"
                placeholder="请选择灭菌日期"
                value-format="yyyy-MM-dd"
                :disabled="surgicalElectrodeSterilizationFormTitle !== '新增一次性使用手术电极灭菌记录表'
                  && surgicalElectrodeSterilizationFormTitle !== '修改一次性使用手术电极灭菌记录表'
                  && surgicalElectrodeSterilizationFormTitle !== '一次性使用手术电极灭菌记录表详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="surgicalElectrodeSterilizationFormTitle === '新增一次性使用手术电极灭菌记录表'
              || surgicalElectrodeSterilizationFormTitle === '修改一次性使用手术电极灭菌记录表'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="detailTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="spec"
              title="型号规格"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="productionBatchNo"
              title="生产批号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="boxQuantity"
              title="箱数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="boxGauge"
              title="箱规"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="totalAmount"
              title="总数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="surgicalElectrodeSterilizationFormTitle === '新增一次性使用手术电极灭菌记录表'
                || surgicalElectrodeSterilizationFormTitle === '修改一次性使用手术电极灭菌记录表'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-form-item>
          <vxe-table
            ref="itemTable"
            border
            resizable
            show-overflow
            keep-source
            :data="itemList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="item"
              title="监测项目"
              width="120"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="claim"
              title="要求"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column field="progressStatus" title="实施情况" width="260">
              <template v-slot="{row,column}">
                <vxe-radio-group v-model="row[column.property]">
                  <vxe-radio label="1" content="按要求实施" />
                  <vxe-radio label="2" content="未按要求未实施" />
                </vxe-radio-group>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="parameterRecord"
              title="参数记录"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
          </vxe-table>
        </el-form-item>
        <el-form-item label="复核结果" prop="reviewResult">
          <el-radio-group
            v-model="surgicalElectrodeSterilizationForm.reviewResult"
            :disabled="surgicalElectrodeSterilizationFormTitle !== '复核一次性使用手术电极灭菌记录表'
              && surgicalElectrodeSterilizationFormTitle !== '一次性使用手术电极灭菌记录表详情'"
          >
            <el-radio :label="1">
              通过
            </el-radio>
            <el-radio :label="2">
              不通过
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="surgicalElectrodeSterilizationDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="surgicalElectrodeSterilizationFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="灭菌批号">
        <el-input v-model="searchForm.sterilizationBatchNo" placeholder="请输入灭菌批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['SC_SSMJ_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="surgicalElectrodeSterilizationPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceName" label="设备名称" />
      <el-table-column prop="deviceNo" label="设备编号" />
      <el-table-column prop="sterilizationBatchNo" label="灭菌批号" />
      <el-table-column label="灭菌日期">
        <template slot-scope="scope">
          <span v-if="scope.row.sterilizationDate">{{ scope.row.sterilizationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="operator" label="操作人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.operationDate">{{ scope.row.operationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['SC_SSMJ_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['SC_SSMJ_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['SC_SSMJ_FH'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="surgicalElectrodeSterilizationPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addSurgicalElectrodeSterilization,
  deleteSurgicalElectrodeSterilization,
  updateSurgicalElectrodeSterilization,
  selectSurgicalElectrodeSterilizationInfo,
  selectSurgicalElectrodeSterilizationList,
  reviewSurgicalElectrodeSterilization
} from '@/api/produce/surgicalElectrodeSterilization'

export default {
  data () {
    return {
      surgicalElectrodeSterilizationDialogVisible: false,
      surgicalElectrodeSterilizationFormTitle: '',
      surgicalElectrodeSterilizationForm: {
        id: '',
        deviceName: '',
        deviceNo: '',
        sterilizationBatchNo: '',
        sterilizationDate: '',
        status: '',
        reviewResult: '',
        detailJson: '',
        itemJson: ''
      },
      surgicalElectrodeSterilizationFormRules: {
        sterilizationBatchNo: [{ required: true, message: '灭菌批号不能为空', trigger: ['blur', 'change']}]
      },
      surgicalElectrodeSterilizationPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        sterilizationBatchNo: ''
      },
      detailList: [],
      itemList: [
        { item: '灭菌前准备', claim: 'A. 检查上水箱是否已充满水。\nB. 检查电源选择开关时否置于手动位置。\nC. 检查电热或蒸气阀、循环泵、真空泵、加药阀、放空阀是否置于关的位置。', progressStatus: '', parameterRecord: '/' },
        { item: '装箱', claim: 'A. 气泵压力设置为0.6Mpa —0.8 Mpa。\nB. 检查门封条是否完好。', progressStatus: '', parameterRecord: '气泵压力监测情况：' },
        { item: '加热', claim: '开加热自动挡按钮，灭菌室温度达到灭菌规定温度时（52度），保温60分钟。', progressStatus: '', parameterRecord: '/' },
        { item: '灭菌室抽真空', claim: 'A. 检查灭菌室温度是否达到灭菌规定温度。\nB. 开真空泵和真空阀。（注意：真空泵和真空阀应同时开启。若真空泵声音不正常，应立即关闭真空泵，检查真空泵进水通路是否通畅。）\nC. 监视压力表，当灭菌室压力达到预真空压力-50Kpa时，关真空阀和真空泵（保压5分钟）。', progressStatus: '', parameterRecord: '灭菌室温度：                压力表：' },
        { item: '加湿', claim: '打开放水阀，当放水阀出来的是蒸汽时，立即关闭放水阀，再打开加蒸汽阀，使蒸汽炉的蒸汽慢慢地向箱体传送。达到30%—85%的湿度要求时关闭各阀门，关闭蒸汽炉的电源。', progressStatus: '', parameterRecord: '关闭阀门前湿度：' },
        { item: '加药', claim: 'A. 确定加药量（5KG）。\nB. 打开气体钢瓶阀。（注意：阀门略微开启以防加药过快。加药时必需强开循环泵。）\nC. 开加药阀。\nD. 当药量达到规定量时，立即关闭钢瓶阀门，等钢瓶阀门上的霜化掉再关加药阀。', progressStatus: '', parameterRecord: '/' },
        { item: '保温（灭菌）', claim: 'A. 灭菌时间（8小时）。\nB. 保温过程应随时监视温度表，包括水箱温度和灭菌室温度。', progressStatus: '', parameterRecord: '温度：          湿度：          压力：' },
        { item: '换气（清洗）', claim: 'A. 当灭菌完毕报警时，说明灭菌时间到。\nB. 开真空泵和真空阀。\nC. 监视压力表-50Kpa时，当灭菌室压力达到规定真空度时，关真空阀和真空泵。\nD. 开放空阀。\nE. 监视压力表，当压力为-5Kpa时，开真空泵和真空阀。（注意：清洗换气至少三次以上，再经30分钟以上的对流清洗方可开门出箱。）', progressStatus: '', parameterRecord: '压力：' },
        { item: '出箱', claim: 'A. 检查灭菌室压力是否确实为零。\nB. 开真空泵。\nC. 真空泵工作数秒后，将门封吸入阀开、关数次。（注意：在上述应确认门封已真正吸入。）\nD. 关真空泵和吸入阀。\nE. 开门。\nF. 出箱。', progressStatus: '', parameterRecord: '/' }
      ]
    }
  },
  created () {
    selectSurgicalElectrodeSterilizationList(this.searchForm).then(res => {
      this.surgicalElectrodeSterilizationPage = res
    })
  },
  methods: {
    surgicalElectrodeSterilizationDialogClose () {
      this.$refs.surgicalElectrodeSterilizationFormRef.resetFields()
      this.detailList = []
      this.itemList = [
        { item: '灭菌前准备', claim: 'A. 检查上水箱是否已充满水。\nB. 检查电源选择开关时否置于手动位置。\nC. 检查电热或蒸气阀、循环泵、真空泵、加药阀、放空阀是否置于关的位置。', progressStatus: '', parameterRecord: '/' },
        { item: '装箱', claim: 'A. 气泵压力设置为0.6Mpa —0.8 Mpa。\nB. 检查门封条是否完好。', progressStatus: '', parameterRecord: '气泵压力监测情况：' },
        { item: '加热', claim: '开加热自动挡按钮，灭菌室温度达到灭菌规定温度时（52度），保温60分钟。', progressStatus: '', parameterRecord: '/' },
        { item: '灭菌室抽真空', claim: 'A. 检查灭菌室温度是否达到灭菌规定温度。\nB. 开真空泵和真空阀。（注意：真空泵和真空阀应同时开启。若真空泵声音不正常，应立即关闭真空泵，检查真空泵进水通路是否通畅。）\nC. 监视压力表，当灭菌室压力达到预真空压力-50Kpa时，关真空阀和真空泵（保压5分钟）。', progressStatus: '', parameterRecord: '灭菌室温度：                压力表：' },
        { item: '加湿', claim: '打开放水阀，当放水阀出来的是蒸汽时，立即关闭放水阀，再打开加蒸汽阀，使蒸汽炉的蒸汽慢慢地向箱体传送。达到30%—85%的湿度要求时关闭各阀门，关闭蒸汽炉的电源。', progressStatus: '', parameterRecord: '关闭阀门前湿度：' },
        { item: '加药', claim: 'A. 确定加药量（5KG）。\nB. 打开气体钢瓶阀。（注意：阀门略微开启以防加药过快。加药时必需强开循环泵。）\nC. 开加药阀。\nD. 当药量达到规定量时，立即关闭钢瓶阀门，等钢瓶阀门上的霜化掉再关加药阀。', progressStatus: '', parameterRecord: '/' },
        { item: '保温（灭菌）', claim: 'A. 灭菌时间（8小时）。\nB. 保温过程应随时监视温度表，包括水箱温度和灭菌室温度。', progressStatus: '', parameterRecord: '温度：          湿度：          压力：' },
        { item: '换气（清洗）', claim: 'A. 当灭菌完毕报警时，说明灭菌时间到。\nB. 开真空泵和真空阀。\nC. 监视压力表-50Kpa时，当灭菌室压力达到规定真空度时，关真空阀和真空泵。\nD. 开放空阀。\nE. 监视压力表，当压力为-5Kpa时，开真空泵和真空阀。（注意：清洗换气至少三次以上，再经30分钟以上的对流清洗方可开门出箱。）', progressStatus: '', parameterRecord: '压力：' },
        { item: '出箱', claim: 'A. 检查灭菌室压力是否确实为零。\nB. 开真空泵。\nC. 真空泵工作数秒后，将门封吸入阀开、关数次。（注意：在上述应确认门封已真正吸入。）\nD. 关真空泵和吸入阀。\nE. 开门。\nF. 出箱。', progressStatus: '', parameterRecord: '/' }
      ]
    },
    surgicalElectrodeSterilizationFormSubmit () {
      if (this.surgicalElectrodeSterilizationFormTitle === '一次性使用手术电极灭菌记录表详情') {
        this.surgicalElectrodeSterilizationDialogVisible = false
        return
      }
      this.$refs.surgicalElectrodeSterilizationFormRef.validate(async valid => {
        if (valid) {
          if (this.surgicalElectrodeSterilizationFormTitle === '新增一次性使用手术电极灭菌记录表') {
            this.surgicalElectrodeSterilizationForm.id = ''
            this.surgicalElectrodeSterilizationForm.status = 1
            this.surgicalElectrodeSterilizationForm.detailJson = JSON.stringify(this.$refs.detailTable.getTableData().tableData)
            this.surgicalElectrodeSterilizationForm.itemJson = JSON.stringify(this.$refs.itemTable.getTableData().tableData)
            await addSurgicalElectrodeSterilization(this.surgicalElectrodeSterilizationForm)
          } else if (this.surgicalElectrodeSterilizationFormTitle === '修改一次性使用手术电极灭菌记录表') {
            this.surgicalElectrodeSterilizationForm.detailJson = JSON.stringify(this.$refs.detailTable.getTableData().tableData)
            this.surgicalElectrodeSterilizationForm.itemJson = JSON.stringify(this.$refs.itemTable.getTableData().tableData)
            await updateSurgicalElectrodeSterilization(this.surgicalElectrodeSterilizationForm)
          } else if (this.surgicalElectrodeSterilizationFormTitle === '复核一次性使用手术电极灭菌记录表') {
            this.surgicalElectrodeSterilizationForm.status = 2
            await reviewSurgicalElectrodeSterilization(this.surgicalElectrodeSterilizationForm)
          }
          this.surgicalElectrodeSterilizationPage = await selectSurgicalElectrodeSterilizationList(this.searchForm)
          this.surgicalElectrodeSterilizationDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.surgicalElectrodeSterilizationFormTitle = '新增一次性使用手术电极灭菌记录表'
      this.surgicalElectrodeSterilizationDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSurgicalElectrodeSterilization(row.id)
        if (this.surgicalElectrodeSterilizationPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.surgicalElectrodeSterilizationPage = await selectSurgicalElectrodeSterilizationList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.surgicalElectrodeSterilizationFormTitle = '修改一次性使用手术电极灭菌记录表'
      this.surgicalElectrodeSterilizationDialogVisible = true
      this.selectSurgicalElectrodeSterilizationInfoById(row.id)
    },
    handleReview (index, row) {
      this.surgicalElectrodeSterilizationFormTitle = '复核一次性使用手术电极灭菌记录表'
      this.surgicalElectrodeSterilizationDialogVisible = true
      this.selectSurgicalElectrodeSterilizationInfoById(row.id)
    },
    handleInfo (index, row) {
      this.surgicalElectrodeSterilizationFormTitle = '一次性使用手术电极灭菌记录表详情'
      this.surgicalElectrodeSterilizationDialogVisible = true
      this.selectSurgicalElectrodeSterilizationInfoById(row.id)
    },
    selectSurgicalElectrodeSterilizationInfoById (id) {
      selectSurgicalElectrodeSterilizationInfo(id).then(res => {
        this.surgicalElectrodeSterilizationForm.id = res.id
        this.surgicalElectrodeSterilizationForm.deviceName = res.deviceName
        this.surgicalElectrodeSterilizationForm.deviceNo = res.deviceNo
        this.surgicalElectrodeSterilizationForm.sterilizationBatchNo = res.sterilizationBatchNo
        this.surgicalElectrodeSterilizationForm.sterilizationDate = res.sterilizationDate
        this.surgicalElectrodeSterilizationForm.status = res.status
        this.surgicalElectrodeSterilizationForm.reviewResult = res.reviewResult
        this.detailList = res.detailList
        this.itemList = res.itemList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectSurgicalElectrodeSterilizationList(this.searchForm).then(res => {
        this.surgicalElectrodeSterilizationPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectSurgicalElectrodeSterilizationList(this.searchForm).then(res => {
        this.surgicalElectrodeSterilizationPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectSurgicalElectrodeSterilizationList(this.searchForm).then(res => {
        this.surgicalElectrodeSterilizationPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.detailTable.insertAt({}, -1)
      await this.$refs.detailTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.detailTable.remove(row)
    }
  }
}
</script>

<style>
</style>
